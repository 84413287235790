.page-hero-header {
    position: relative;
}

.page-hero-header>div:last-child {
    position: absolute;
    gap: 1.2rem;
    top: 50%;
    left: min(8vw, 15.2rem);
    transform: translateY(-50%);
}

.page-hero-header .highlight-2 {
    color: var(--neutral-text-title-reverse-color);
}

.page-hero-header .subtitle-3 {
    color: var(--neutral-text-body-reverse-color);
}

.project-infor-container {
    gap: 7vw;
    flex-wrap: wrap;
    padding: min(4.8vw, 8rem) min(8vw, 15.2rem);
    align-items: start;
}

.project-infor-container .comp-text[class*="highlight-"] {
    font-family: "Source Serif 4";
}

.project-infor-container {
    --gutter: 7vw;
}

.project-infor-container>div:last-child p {
    margin: 0;
}

.ktx-cate-container {
    padding: min(5.2vw, 10rem) min(8vw, 15.2rem);
    gap: 6.4rem;
}

.parent-news-container {
    --gutter: 2.4rem;
    gap: 1.6rem
}

.parent-news-container>a:has(>img) {
    width: 100%;
    height: 26.4rem;
    border-radius: 0.8rem;
    background-color: var(--neutral-sub1-background-color);
    object-fit: contain;
    object-position: center;
    overflow: hidden;
}

.parent-news-container>a>img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.parent-news-container>a:has(>img) {
    transition: all 0.2s ease-in-out;
}

.parent-news-container>a:has(>img):hover>img {
    scale: 1.1;
    transform-origin: center center;
}

.html-text-value * {
    text-align: justify;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    overflow: hidden;
    /* display: -webkit-box; */
    -webkit-line-clamp: var(--max-line);
    -webkit-box-orient: vertical;
}

.ktx-group-container-item>a:first-child {
    --gutter: 3.2rem;
    width: 34.2rem;
    height: 22rem;
    border-radius: 0.8rem
}

.ktx-group-container-item>a:has(>img):hover>img {
    scale: 1.1;
    transform-origin: center center;
}

@media only screen and (max-width: 576px) {
    :root {
        font-size: 6.4px !important;
    }

    div[class*="footer_copyright"] .highlight-4 {
        font-size: 2.8rem !important;
    }
}