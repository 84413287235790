.recruit-header {
    width: var(--header-height);
    height: 6.4rem;
    padding: 0 min(8vw, 15.2rem);
    border-bottom: var(--neutral-main-border-color);
    background-color: var(--neutral-absolute-background-color);
    z-index: 2;
    position: fixed;
}

.recruit-header>div:has(>input) {
    background-color: var(--neutral-sub1-background-color);
    border-radius: 10rem;
    width: 29vw;
    min-width: 140px;
    height: 4rem;
}

.recruit-body {
    padding: 4rem min(8vw, 15.2rem) min(5.2vw, 10rem);
}