.parent-doc-container {
    --gutter: 2.4rem;
    gap: 1.6rem
}

.parent-doc-container>a:has(>img) {
    width: 100%;
    height: 26.4rem;
    border-radius: 0.8rem;
    background-color: var(--neutral-sub1-background-color);
    object-fit: contain;
    object-position: center;
    overflow: hidden;
}

.parent-doc-container>a>img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.parent-doc-container>a:has(>img) {
    transition: all 0.2s ease-in-out;
}

.parent-doc-container>a:has(>img):hover>img {
    scale: 1.1;
    transform-origin: center center;
}

.list-document-container {
    gap: 2.4rem;
    flex: 1;
    overflow: hidden auto;
    align-items: start;
}

.list-document-container>div>div>a:hover {
    text-decoration: underline;
    /* color: var(--primary-main-color); */
}

.document-container {
    --gutter: 2.4rem;
    height: 14.8rem;
    border-radius: 0.8rem;
    padding: 4rem;
    border: var(--neutral-main-border-color);
}

.document-container:hover {
    background-color: var(--neutral-hover-background-color);
}

.document-container-knowledge {
    --gutter: 2.4rem;
    gap: 1.6rem;
}

.document-container-knowledge>a:has(>img) {
    width: 100%;
    border-radius: 0.8rem;
    background-color: var(--neutral-sub1-background-color);
    object-fit: contain;
    object-position: center;
    overflow: hidden;
}

.document-container-knowledge>a:has(>img):hover>img {
    scale: 1.1;
    transform-origin: center center;
    transition: all 0.2s ease-in-out;
}

.document-container-question {
    width: 100%;
    padding: 1.2rem 0;
    gap: 4rem;
}