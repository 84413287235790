.list-customer-container {
    flex-wrap: wrap;
    align-items: stretch;
    gap: 4rem 2.4rem;
    padding: min(5.2vw, 10rem) min(8vw, 15.2rem);
}

.customer-container {
    --gutter: 2.4rem;
    gap: 1.6rem;
}

.customer-container[customer-type="partner"] {
    padding: 2.4rem;
    border-radius: 0.8rem;
    border: var(--neutral-main-border-color);
    align-items: center;
}

.customer-container>a:has(>img) {
    height: 8.8rem;
    max-width: 100%;
    border-radius: 0.8rem;
    object-position: center;
    overflow: hidden;
}

.customer-container[customer-type="partner"] div[class*="comp-text"] {
    text-align: center;
}

.customer-container>a:has(>img):hover>img {
    transition: all 0.2s ease-in-out;
    scale: 1.1;
    transform-origin: center center;
}

.consultancy-card-container {
    padding: 5.6rem min(8vw, 15.2rem);
    gap: 2.4rem;
    background-image: url("../../assets/background.png");
    background-size: 100%;
    background-color: var(--primary-darker-color);
}

.consultancy-card-container div[class*="heading-4"] {
    color: var(--neutral-text-title-reverse-color);
}

.consultancy-card-container div[class*="body-3"] {
    color: var(--neutral-text-title-reverse-color);
}