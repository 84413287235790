@import url('./assets/skin/root.css');

html,
body,
#root {
    width: 100vw;
    height: 100dvh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
}

body * {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    font-family: 'Inter' !important;
}

body * {
    background-color: transparent;
    outline: none;
}

button,
input,
a {
    text-decoration: none;
    outline: none;
    padding: 0;
    border: none;
}

button,
button * {
    cursor: pointer;
}

.main-layout {
    width: 100vw;
    height: 100dvh;
    overflow: hidden auto !important;
}

.main-layout.row {
    flex-wrap: wrap;
    align-items: start !important;
    align-content: start !important;
}

.main-layout>.layout-body {
    flex: 1;
    min-height: 100dvh;
}

@media only screen and (max-width: 768px) {
    .main-layout>.layout-body {
        flex: 1;
        min-height: calc(100dvh - 5.6rem);
    }
}

::-webkit-scrollbar {
    width: 0.6rem;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 0.4rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 0.4rem;
    background-color: #c1c1c1;
    visibility: hidden;
}

*:hover::-webkit-scrollbar-thumb {
    visibility: visible;
}

.page-container::-webkit-scrollbar,
.main-layout::-webkit-scrollbar {
    width: 0.8rem;
}

.col>.remain {
    flex: 1 !important;
    height: 100% !important;
}

@keyframes placeholder-animation {
    0% {
        background-position: -1000px 0
    }
    
    100% {
        background-position: 1000px 0
    }
}

.placeholder-animation {
    animation: placeholder-animation 4.2s linear infinite forwards;
    background: linear-gradient(to right, #cbd5e1 8%, #f1f5f9 18%, #cbd5e1 33%);
}