.lib-header {
    width: var(--header-height);
    height: 6.4rem;
    padding: 0 min(8vw, 15.2rem);
    border-bottom: var(--neutral-main-border-color);
    background-color: var(--neutral-absolute-background-color);
    position: fixed;
    z-index: 3;
}

.lib-header>div:has(>input) {
    background-color: var(--neutral-sub1-background-color);
    border-radius: 10rem;
    width: 29vw;
    min-width: 140px;
    height: 4rem;
}

.lib-body {
    padding: 4rem min(8vw, 15.2rem) min(5.2vw, 10rem);
    gap: 3.2rem;
    flex: 1;
}

.new-header {
    width: 100%;
    height: 6.4rem;
    padding: 0 min(8vw, 15.2rem);
    border-bottom: var(--neutral-main-border-color);
    background-color: var(--neutral-absolute-background-color);
}

.relative-news:hover * {
    text-decoration: underline;
}