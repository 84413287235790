.card-contact-container {
    gap: 6vw;
    width: 100%;
    flex-wrap: wrap;
}

.card-contact-container>* {
    --gutter: 6vw;
}

.card-contact-container>img {
    width: 100%;
    border-radius: 0.8rem;
    object-fit: cover;
}