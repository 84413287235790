.page-title-header {
    position: relative;
}

.page-title-header div[class*="highlight-2"] {
    color: var(--neutral-text-title-reverse-color);
}

.page-title-header div[class*="subtitle-2"] {
    color: var(--neutral-text-body-reverse-color);
}

.description-text * {
    text-align: justify;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}