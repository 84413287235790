.module-tile {
    gap: 1.2rem;
    padding: 1rem 1.2rem;
    border-radius: 0.8rem;
    width: 100% !important;
}

.module-tile:hover {
    background-color: var(--neutral-hover-background-color);
}

.module-tile.selected {
    background-color: var(--neutral-selected-background-color);
}

div:has(>.module-tile:first-child) {
    display: none;
}

div:has(>.module-tile:first-child div.open)>div {
    display: flex;
}

.menu-container>div {
    display: flex !important;
}