.card-product-container {
    padding: min(5.2vw, 10rem) min(8vw, 15.2rem);
}

.card-product-item>div {
    flex-wrap: wrap;
    align-items: start;
}

.card-product-item>div:first-child>* {
    --gutter: 7dvw;
}

.card-product-item>div:last-child>* {
    --gutter: 4rem;
}