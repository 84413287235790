.list-news-container {
    flex-wrap: wrap;
    gap: 4.0rem;
    flex: 1;
    overflow: hidden auto;
    align-items: start;
}

.news-container {
    --gutter: 2.4rem;
    flex-wrap: wrap;
    align-items: start;
}

.news-container>* {
    --gutter: 2.4rem;
}

.news-container>a:has(>img) {
    width: 100%;
    height: 14rem;
    max-width: 42.4rem;
    border-radius: 0.8rem;
    background-color: var(--neutral-sub1-background-color);
    object-fit: contain;
    object-position: center;
    overflow: hidden;
}

.news-container>a>img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.page-title-header {
    gap: 0.8rem;
    padding: 1.3rem 4.8rem 0 12rem;
    flex: 1;
}

.page-title-header div[class*="heading-6"] {
    font-family: 'Inter';
    color: var(--neutral-text-title-reverse-color);
}

.title-header-item {
    gap: 0.8rem;
    flex: 1;
}

.page-title-header div[class*="subtitle-3"] {
    color: var(--neutral-text-body-reverse-color);
}

.description-text * {
    text-align: justify;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.news-container>a:has(>img) {
    transition: all 0.2s ease-in-out;
}

.news-container>a:has(>img):hover>img {
    scale: 1.1;
    transform-origin: center center;
}