.list-recruit-container {
    flex-wrap: wrap;
    gap: 2.4rem;
    flex: 1;
    overflow: hidden auto;
    align-items: start;
}

.recruit-container {
    --gutter: 2.4rem;
    border-radius: 0.8rem;
    padding: 4rem;
    border: var(--neutral-main-border-color);
}

.recruit-container:hover {
    background-color: var(--neutral-hover-background-color);
}