.customer-container {
    --gutter: 2.4rem;
}

.customer-container>a:has(>img) {
    width: 100%;
    height: 26.4rem;
    border-radius: 0.8rem;
    background-color: var(--neutral-sub1-background-color);
    object-fit: contain;
    object-position: center;
    overflow: hidden;
}

.customer-container>a>img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.page-title-header {
    gap: 0.8rem;
    flex: 1;
}

.page-title-header div[class*="heading-5"] {
    font-family: 'Inter';
    color: var(--neutral-text-title-reverse-color);
}

.title-header-item {
    gap: 0.8rem;
    padding-top: 1.6rem;
}

.page-title-header div[class*="subtitle-2"] {
    color: var(--neutral-text-body-reverse-color);
}

.description-container[class*="row"] {
    align-items: start;
    flex-wrap: wrap;
    gap: 6.25vw;
    width: 100%;
}

.description-container[class*="row"]>* {
    --gutter: 6.25vw;
}

.description-text * {
    text-align: justify;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    overflow: hidden;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.customer-container>a:has(>img) {
    transition: all 0.2s ease-in-out;
}

.customer-container>a:has(>img):hover>img {
    scale: 1.1;
    transform-origin: center center;
}