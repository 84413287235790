.sidebar {
    --gutter: 0px;
    position: sticky;
    top: 0px;
    height: 100vh;
    width: 29.6rem;
    background-color: var(--primary-background);
    z-index: 8;
}

.logo-container {
    padding: 1.6em;
    gap: 0.4rem;
    background-color: #CFE6D9;
    justify-content: center;
}

.logo-container>img {
    width: 3.8rem;
}

.logo-subtitle {
    font-family: 'Source Serif 4' !important;
    font-size: 1.1rem;
    line-height: 1.6rem;
    font-style: italic
}

.logo-container div[class*="comp-text"] {
    font-weight: 500 !important;
    color: var(--primary-darker-color) !important;
    flex: 1;
}

/* .main-layout:has(>.header)>.sidebar {
    top: 5.6rem;
    height: calc(100vh - 5.6rem);
} */

div:has(>.sidebar-action) {
    padding: 1.6rem;
    gap: 0.8rem;
    border-top: 1px solid var(--primary-border-color);
}

.sidebar-action {
    height: 4.8rem;
    border: 1px solid var(--primary-border-color);
    color: var(--neutral-text-subtitle-color);
    background-color: transparent;
}

.header {
    --gutter: 0px;
    top: 0;
    position: sticky;
    width: 100vw;
    height: 5.6rem;
    padding: 1.2rem 2.4rem;
    gap: 1.6rem;
    border-bottom: var(--neutral-main-border-color);
    background-color: var(--neutral-main-background-color);
    z-index: 9;
    background-color: #CFE6D9;
}

.header>.logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 1.6rem;
    justify-content: center;
    gap: 4px;
}

.logo div[class*="comp-text"] {
    font-weight: 500 !important;
    color: var(--primary-darker-color) !important;
    align-items: stretch;
}