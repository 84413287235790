.footer {
    background-color: var(--neutral-absolute-reverse-background-color);
    padding: min(5.2vw, 10rem) min(8vw, 15.2rem);
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 4rem;
}

.copyright {
    --gutter: 0px;
    gap: 2.4rem;
    overflow: visible;
}

.copyright img {
    height: 9.3rem;
}

.copyright div[class*="highlight-4"] {
    position: relative;
    color: var(--neutral-text-title-reverse-color);
    overflow: visible;
}

.copyright div[class*="highlight-4"]::after {
    content: "2024 copyright by Kiến tạo xanh";
    position: absolute;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: var(--neutral-text-subtitle-reverse-color);
    bottom: -3.8rem;
    left: 0;
}

.link-to {
    --gutter: 0px;
    align-items: start;
    /* width: 26.4%; */
    gap: 18rem;
}